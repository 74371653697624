exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artikkelit-js": () => import("./../../../src/pages/artikkelit.js" /* webpackChunkName: "component---src-pages-artikkelit-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kilpailuta-remonttilaina-js": () => import("./../../../src/pages/kilpailuta/remonttilaina.js" /* webpackChunkName: "component---src-pages-kilpailuta-remonttilaina-js" */),
  "component---src-pages-remonttien-hinnat-js": () => import("./../../../src/pages/remonttien-hinnat.js" /* webpackChunkName: "component---src-pages-remonttien-hinnat-js" */),
  "component---src-pages-urakoitsijalle-js": () => import("./../../../src/pages/urakoitsijalle.js" /* webpackChunkName: "component---src-pages-urakoitsijalle-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/articleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-job-cost-template-js": () => import("./../../../src/templates/jobCostTemplate.js" /* webpackChunkName: "component---src-templates-job-cost-template-js" */),
  "component---src-templates-jobs-by-city-template-js": () => import("./../../../src/templates/jobsByCityTemplate.js" /* webpackChunkName: "component---src-templates-jobs-by-city-template-js" */),
  "component---src-templates-jobs-by-region-template-js": () => import("./../../../src/templates/jobsByRegionTemplate.js" /* webpackChunkName: "component---src-templates-jobs-by-region-template-js" */),
  "component---src-templates-kotitalousvahennyslaskuri-template-js": () => import("./../../../src/templates/kotitalousvahennyslaskuriTemplate.js" /* webpackChunkName: "component---src-templates-kotitalousvahennyslaskuri-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-remonttilaina-template-js": () => import("./../../../src/templates/remonttilainaTemplate.js" /* webpackChunkName: "component---src-templates-remonttilaina-template-js" */)
}

